import { template as template_3e86b15781894bd2a6ea1214ca2d7b85 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_3e86b15781894bd2a6ea1214ca2d7b85(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
