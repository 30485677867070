import { template as template_ec9ecec8616f4fd3af791566b321804f } from "@ember/template-compiler";
const FKControlMenuContainer = template_ec9ecec8616f4fd3af791566b321804f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
