import { template as template_6efc577e202a445aba0b61b661fbb9cd } from "@ember/template-compiler";
const FKLabel = template_6efc577e202a445aba0b61b661fbb9cd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
