import { template as template_2685f9f373064ecd97ba3dcf3c9e8a72 } from "@ember/template-compiler";
const WelcomeHeader = template_2685f9f373064ecd97ba3dcf3c9e8a72(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
